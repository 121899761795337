import React, {useContext} from "react";
import "./WorkExperience.css";
import ExperienceCard from "../../components/experienceCard/ExperienceCard";
import {workExperiences} from "../../portfolio";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";

export default function WorkExperience() {
  const {isDark} = useContext(StyleContext);
  if (workExperiences.display) {
    return (
      <div id="experience">
        <Fade bottom duration={1000} distance="20px">
          <div className="experience-container" id="workExperience">
            <div>
            <h1 className="experience-heading">
              Experience
              <span role="img" aria-label="briefcase-emoji">
                💼
              </span>
            </h1>
            <div className="timeline">
              {workExperiences.experience.map((card, i) => (
                <div className={`timeline-item ${i % 2 === 0 ? "left" : "right"}`} key={i}>
                  <div className="timeline-opposite-content">
                    <span>{card.date}</span>
                  </div>
                  <div className="timeline-separator">
                    <div className="timeline-connector"></div>
                    <div className="timeline-dot">
                      <img src={card.companylogo} alt={card.company} className="timeline-logo" />
                    </div>
                    <div className="timeline-connector"></div>
                  </div>
                  <div className="timeline-content">
                    <ExperienceCard
                      isDark={isDark}
                      cardInfo={{
                        company: card.company,
                        desc: card.desc,
                        date: card.date,
                        companylogo: card.companylogo,
                        role: card.role,
                        descBullets: card.descBullets
                      }}
                    />
                  </div>
                </div>
              ))}
              </div>
            </div>
          </div>
        </Fade>
      </div>
    );
  }
  return null;
}
